<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="header">
          <div class="type">
            <div class="nearly_day fw topBg_current">
              <span class="current_color">心电</span>
            </div>
            <div @click="handleSelectItem('blood_pressure')" class="nearly_day fw topBg">
              <span class="color">血压</span>
            </div>
            <div @click="handleSelectItem('blood_glucose')" class="nearly_day fw topBg">
              <span class="color">血糖</span>
            </div>
          </div>
          <!-- 选择医院 -->
          <div class="search">
            <div style="display: inline-block">
              <span style="margin-right: 5px">{{ addressStr }}</span>
            </div>
            <span style="margin-right: 5px">{{
              handleString(hospitalName)
            }}</span>
            <span style="margin-left: 5px; cursor: pointer" @click="handleSelectHospital">
              <i class="el-icon-setting"></i>
            </span>
          </div>
          <img src="@/assets/header_left.png" style="width: 758px; height: 35px" />
          <img src="@/assets/header_center.png" style="width: 404px; height: 72px" />
          <img src="@/assets/header_right.png" style="width: 758px; height: 35px" />
        </div>
        <div class="header-data">
          <div v-for="(item, index) in handleV3Head" :class="[item.isSpace ? 'space_m' : '']" :key="index">
            <dv-border-box-8>
              <div class="space">
                <span class="value"> {{ item.count }}</span>
                <span class="label"> {{ item.title }}</span>
              </div>
            </dv-border-box-8>
          </div>
        </div>
        <div class="body-box">
          <div class="first_line">
            <!-- 监测数据 -->
            <div class="space">
              <MonitoringData />
            </div>
            <!-- 站点统计 -->
            <div class="space">
              <SiteStatistics />
            </div>
            <!-- BD市场工作统计 -->
            <div class="space">
              <Bd />
            </div>
          </div>
          <div class="second_line">
            <!-- 近两月待上传率等-->
            <div class="space">
              <LastTwoMonths />
            </div>
            <!-- 实时监测 -->
            <div class="space">
              <Supervision />
            </div>
            <!-- 绩效KPI -->
            <div class="space">
              <Kpi />
            </div>
          </div>
          <div class="third_line">
            <!-- 异常预警 -->
            <div class="space">
              <Abnormal />
            </div>
            <!-- 实时建档 -->
            <div class="space">
              <Filing />
            </div>
            <!-- 库存top5 -->
            <div class="space">
              <TopFive />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :modal="false" title="设置" :visible.sync="dialogVisible" width="30%" @open="handleOpen"
      @close="handleClose">
      <el-form>
        <el-form-item label-width="100px" label="当前用户:">
          <div class="flex-center" style="width: 60%">
            <img src="@/assets/default-avatar.png" style="width: 50px; height: 50px; border-radius: 50%" />
            <span class="title-item" style="margin: 0 10px; max-width: 80px">{{
              userInfo.name
            }}</span>
            <span class="title-item">{{ userInfo.phone }}</span>
          </div>
        </el-form-item>
        <el-form-item v-if="!oneHospitalName" label-width="100px" label="选择省市区:">
          <el-cascader style="width: 100%" size="mini" v-model="address" :options="addressJson"
            :props="addressListProps" @change="selectAddrssChange" placeholder="请选择医院地址" clearable></el-cascader>
        </el-form-item>
        <el-form-item v-if="!oneHospitalName" label-width="100px" label="选择医院:">
          <el-cascader v-model="hospitalId" :options="options" :disabled="address.length !== 3" size="mini"
            style="width: 100%" placeholder="请选择医院" :props="cateListProps"></el-cascader>
          <!-- <el-button
            style="margin-left: 10px"
            size="mini"
            type="primary"
            :disabled="!hospitalId.length"
            @click="hanldeConfirm"
            >确 定</el-button
          >
          <el-button size="mini" plain @click="hanldeReset">重 置</el-button> -->
        </el-form-item>
        <el-form-item label-width="100px" label="数据脱敏:">
          <el-checkbox-group v-model="desensitization">
            <el-checkbox label="是否数据脱敏"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="100px" label="账号管理:">
          <el-popconfirm title="是否退出登录" @confirm="handleLogOut">
            <el-button slot="reference" size="mini">退出登录</el-button>
          </el-popconfirm>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="margin-left: 10px" size="mini" type="primary" @click="hanldeConfirm">确 定</el-button>
        <!-- <el-button size="mini" @click="handleLogOut">退出登录</el-button> -->
        <el-button size="mini" plain @click="hanldeReset">重 置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { head } from "@/api/ecg/head.js";
import { hospital } from "@/api/hospital.js";
import { refresh } from "@/api/ecg/refresh.js";
import { logout } from "@/api/login.js";
import { province } from "@/api/province.js";
import { loginUser } from "@/api/loginUser.js";
import { getUrlParams, handleString, trimSpace } from "@/utils";
import MonitoringData from "./components/monitoringData.vue";
import SiteStatistics from "./components/siteStatistics.vue";
import Bd from "./components/bd.vue";
import LastTwoMonths from "./components/lastTwoMonths.vue";
import Supervision from "./components/supervision.vue";
import Kpi from "./components/kpi.vue";
import Abnormal from "./components/abnormal.vue";
import Filing from "./components/filing.vue";
import TopFive from "./components/topFive.vue";
import { handleProvince } from "@/utils";

import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";

export default {
  mixins: [drawMixin],
  components: {
    MonitoringData,
    SiteStatistics,
    Bd,
    LastTwoMonths,
    Supervision,
    Kpi,
    Abnormal,
    Filing,
    TopFive,
  },
  data() {
    return {
      isDisabled: false,
      loading: false,
      desensitization: false,
      addressStr: "",
      address: [],
      addressJson: [],
      userInfo: {}, // 用户信息
      cateListProps: {
        value: "id", //匹配响应数据中的id
        label: "name", //匹配响应数据中的name
        checkStrictly: true,
        children: "children", //匹配响应数据中的children
      },
      addressListProps: {
        value: "name", //匹配响应数据中的id
        label: "name", //匹配响应数据中的name
        checkStrictly: true,
        children: "districts", //匹配响应数据中的children
      },
      updatetimeing: null,
      options: [],
      hospitalName: "",
      hospitalId: [],
      hospitalList: [],
      dialogVisible: false,
      oneHospitalName: false,
    };
  },
  computed: {
    ...mapState(getStore, ["handleV3Head"]),
  },
  created() {
    this.desensitization =
      JSON.parse(sessionStorage.getItem("desensitization")) ?? false;
    let hospitalIdList = sessionStorage.getItem("hospitalIdList") ?? [];
    let currentHospitalName = sessionStorage.getItem("currentHospitalName");
    if (currentHospitalName) {
      this.hospitalName = currentHospitalName;
      this.hospitalId = hospitalIdList.split(",");
      let currentHospitalId = this.hospitalId.length
        ? this.hospitalId[this.hospitalId.length - 1]
        : "";
      getStore().$patch((store) => {
        store.currentHospitalId = currentHospitalId;
      });
      // sessionStorage.setItem("currentHospitalId", currentHospitalId);
    }
    this.address = trimSpace(
      JSON.parse(sessionStorage.getItem("province")) ?? []
    );
    this.addressStr = this.address.join("/");
    let province = handleProvince(
      JSON.parse(sessionStorage.getItem("province")) ?? []
    );
    getStore().$patch((store) => {
      store.province = province;
    });
    this.getV3HeartRateAggregate();
  },
  mounted() {
    let province = handleProvince(this.address);
    this.getHospitalList(province);
    this.getProvince();
    this.changeTiming();
    this.getloginUser();
  },
  methods: {
    handleString,
    ...mapActions(getStore, ["getV3HeartRateAggregate"]),
    getHospitalList(province) {
      hospital.getData(province).then(async (res) => {
        let options = res.data;
        this.options = await this.getTreeData(options);
        if (res.data.length === 1) {
          const hospitalInfo = this.handleDistrictsLength(res.data[0].children);
          if (hospitalInfo) {
            this.oneHospitalName = true
            this.hospitalName = hospitalInfo[0].name;
          }
        }
      });
    },
    // 树结构扁平化
    handleDataRecursion(tree = [], key, children = "children") {
      let queen = [];
      let out = [];
      queen = queen.concat(tree);
      while (queen.length) {
        let first = queen.shift();
        if (first[children]) {
          queen = queen.concat(first[children]);
          delete first[children];
        }
        if (key) {
          out.push(first[key]);
        } else {
          out.push(first);
        }
      }
      return (out);
    },
    getProvince() {
      province.getProvince().then(async (res) => {
        this.addressJson = await this.getAddressData(res.data.districts);
      });
    },
    handleDistrictsLength(districts) {
      if (districts.length === 1) {
        if (districts[0].children) {
          return this.handleDistrictsLength(districts[0].children);
        } else {
          return districts;
        }
      } else {
        if (districts.children) {
          return districts[0].children;
        } else {
          return districts;
        }
      }
    },
    getloginUser() {
      loginUser.getData().then((res) => {
        let { name, phone } = res.data;
        this.userInfo = { name, phone };
      });
    },
    // 退出登录
    handleLogOut() {
      logout.postLogout().then((res) => {
        if (res.code === "OK") {
          sessionStorage.clear();
          this.$router.replace("/");
        }
      });
    },
    // 递归处理json文件的最后一级
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      data.map((item) => {
        item.name = this.handleString(item.name);
        return item;
      });
      return data;
    },
    // 递归处理json文件的最后一级
    getAddressData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].districts.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].districts = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getAddressData(data[i].districts);
        }
      }
      return data;
    },
    handleSelectHospital() {
      let province = JSON.parse(sessionStorage.getItem("province")) ?? [];
      this.address = trimSpace(province);
      let hospitalIdList = sessionStorage.getItem("hospitalIdList") ?? [];
      if (hospitalIdList instanceof Array) {
        this.hospitalId = [];
      } else {
        this.hospitalId = hospitalIdList?.split(",");
      }
      this.dialogVisible = true;
    },
    handleOpen() {
      const hosArr = sessionStorage.getItem("hospitalIdList")?.split(",");
      let province = JSON.parse(sessionStorage.getItem("province")) ?? [];
      if (hosArr) {
        this.hospitalId = hosArr;
      }
      if (province.length) {
        province = handleProvince(province);
        this.getHospitalList(province);
      }
    },
    handleClose() {
      let province = sessionStorage.getItem("province");
      if (!JSON.parse(province)) {
        this.address = [];
        this.hospitalId = [];
      }
    },
    hanldeConfirm() {
      this.hospitalId = trimSpace(this.hospitalId);
      if (this.hospitalId.length > 0) {
        sessionStorage.setItem("hospitalIdList", this.hospitalId);
        sessionStorage.setItem("hosArr", JSON.stringify(this.hospitalId));
        hospital
          .getInfo(this.hospitalId[this.hospitalId.length - 1])
          .then((res) => {
            this.hospitalName = res.data.name;
            sessionStorage.setItem("currentHospitalName", res.data.name);
            this.dialogVisible = false;
            sessionStorage.setItem("province", JSON.stringify(this.address));
            sessionStorage.setItem("desensitization", this.desensitization);
            setTimeout(() => {
              location.reload();
            }, 200);
          });
      } else {
        sessionStorage.removeItem("currentHospitalName");
        sessionStorage.removeItem("currentHospitalId");
        sessionStorage.setItem("province", JSON.stringify(this.address));
        sessionStorage.setItem("desensitization", this.desensitization);
        setTimeout(() => {
          location.reload();
        }, 200);
      }
    },
    hanldeReset() {
      // sessionStorage.clear();
      sessionStorage.removeItem("currentHospitalName");
      sessionStorage.removeItem("currentHospitalId");
      sessionStorage.removeItem("hosArr");
      sessionStorage.removeItem("province");
      sessionStorage.removeItem("hospitalIdList");
      this.dialogVisible = false;
      location.reload();
    },
    handleSelectItem(val) {
      sessionStorage.setItem("disease_type", val);
      this.$router.push("chronic");
    },
    selectAddrssChange(val) {
      console.log(val)
      this.hospitalId = [];
      let province = handleProvince(val);
      this.getHospitalList(province);
    },
    changeTiming() {
      this.updatetimeing = setInterval(() => {
        refresh.refreshData().then((res) => {
          if (res.data) {
            location.reload();
          }
        });
      }, 60000);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  justify-content: space-between;

  .type {
    position: absolute;
    top: 26px;
    left: 24px;
    display: flex;
  }
}

.header-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 16px;

  .space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 107px;
    background-image: url("~@/assets/header_space.png");
    background-size: 100% 100%;

    .label {
      font-size: 12px;
      margin-top: 15px;
    }

    .value {
      // font-family: 黑体 !important;
      // font-family: HeadLineA Regular !important;
      font-size: 30px;
      line-height: 30px;
      font-weight: 700;
      background-image: linear-gradient(to top, #01e0ff, #0171ff);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
}

.space_m {
  background-image: url("~@/assets/header_space_m.png") !important;
}

.search {
  position: absolute;
  right: 35px;
  top: 26px;
  font-size: 14px;
}

.nearly_day,
.nearly_year {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 22px;
  margin-left: 10px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-size: 12px;
  cursor: pointer;
}

.current_color {
  background: linear-gradient(180deg, #eeeeee 0%, #e7c618 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color {
  background: linear-gradient(180deg, #eeeeee 0%, #84e2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.topBg {
  background-image: url("~@/assets/chart_space.png");
  background-size: 100% 100%;
  background-position: center center;
}

.topBg_current {
  background-image: url("~@/assets/chart_space_current.png");
  background-size: 100% 100%;
  background-position: center center;
}

.animationleft {
  position: absolute;
  top: 32px;
  left: 480px;
}

.animationRight {
  position: absolute;
  top: 32px;
  right: 480px;
}

.dialog-box {
  padding: 20px 6px;
}
</style>
