import { defineStore } from "pinia";
import { aggregate } from "@/api/ecg/aggregate.js";
import { handleString, handleProvince } from "@/utils";

export const getStore = defineStore('useEcg', {
    state: () => ({
        current: 1,
        BdCurrent: 0,
        currentHospitalId: '',
        countStartTime: '',
        countEndTime: '',
        province: {},
        head: [
            { id: 1, label: "中心数量", value: "0" },
            { id: 2, label: "服务站数量", value: "0" },
            { id: 3, label: "医师总人数", value: "0" },
            { id: 4, label: "建档总人数", value: "0" },
            { id: 5, label: "已监测总人数", value: "0" },
            { id: 6, label: "已监测总次数", value: "0", isSpace: true },
            { id: 7, label: "已上传总人数", value: "0", isSpace: true },
            { id: 8, label: "已上传总次数", value: "0", isSpace: true },
            { id: 9, label: "已上传体检人数", value: "0", isSpace: true },
            { id: 10, label: "已上传体检次数", value: "0", isSpace: true },
            { id: 11, label: "已上传常规人数", value: "0" },
            { id: 12, label: "已上传常规次数", value: "0" },
            { id: 13, label: "待上传", value: "0" },
            { id: 14, label: "已审核", value: "0" },
            { id: 15, label: "待审核", value: "0" },
        ],
        monitor: [],
        service: [],
        upLoadRate: {},
        realTimeData: {},
        marketWorkStatisticsVO: {},
        realTime: {},
        kpiData: {},
        kpi: {},
        device: [], //  设备异常
        apply: [], //   应用异常
        archive: [],
        stock: {}, // top5
    }),
    getters: {
        handleV3Head(state) {
            return state.head.map((item, index) => {
                if (index > 4 && index < 10) {
                    item.isSpace = true;
                }
                return item;
            });
        },
        handleV3Monitor(state) {
            const xAxisData = state.monitor.xData
            const serieData = state.monitor.serieData
            let cdata = {
                color: [
                    "#FFD700",
                    "#05FF00",
                    "#00FDFF",
                    "#0170FE",
                    "#4900FF",
                    "#9E00FF",
                    "#FE01FD",
                ],
                grid: {
                    top: 40,
                    left: 60,
                    right: 60,
                },
                legend: {
                    icon: "roundRect",
                    top: 10,
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 8, // 设置 legend 中文字的字体大小为 14
                    },
                    data: [
                        "已监测总人数",
                        "已监测总次数",
                        "已上传总人数",
                        "已上传总次数",
                        "待上传",
                        "已审核",
                        "待审核",
                    ],
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "category",
                    // boundaryGap: true,
                    data: xAxisData,
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                yAxis: [
                    // 人数
                    {
                        type: "value",
                        name: "( 人数 )",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                    // 次数
                    {
                        type: "value",
                        name: "( 次数 )",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "待审核",
                        type: "line",
                        yAxisIndex: 1,
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.people,
                    },
                    {
                        name: "已审核",
                        type: "line",
                        yAxisIndex: 1,
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.count,
                    },
                    {
                        name: "待上传",
                        type: "line",
                        yAxisIndex: 1,
                        stack: "Total",
                        symbol: "none",
                        // label: {
                        //   show: true,
                        //   position: "top",
                        // },
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.uploadCompletePeople,
                    },
                    {
                        name: "已上传总次数",
                        type: "line",
                        yAxisIndex: 1,
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.uploadCompleteCount,
                    },
                    {
                        name: "已上传总人数",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.uploadIng,
                    },
                    {
                        name: "已监测总次数",
                        yAxisIndex: 1,
                        type: "line",
                        symbol: "none",
                        stack: "Total",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.platformCompleteAudit,
                    },
                    {
                        name: "已监测总人数",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.toAudit,
                    },
                ],
            }
            return cdata
        },
        handleV3Service(state) {
            const config = {
                header: [
                    "医院名称",
                    "已监测总人数",
                    "已监测总次数",
                    "待上传",
                    "已审核",
                    "待审核",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "145", "145", "80", "80", "80"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.service,
            }
            return config
        },
        handleV3Bd(state) {
            const xAxisData = state.marketWorkStatisticsVO?.echarts?.xData ?? []
            const serieData = state.marketWorkStatisticsVO?.echarts?.serieData
            const cdata = {
                color: ["#0171FF", "#00FDFF"],
                grid: {
                    top: 30,
                    left: 50,
                    right: 10,
                    height: "130",
                },
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 8, // 设置 legend 中文字的字体大小为 14
                    },
                    data: ["监测次数", "上传次数"],
                    top: "10px",
                    right: "0%",
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        fontSize: 10,
                    },
                    data: xAxisData,
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                dataZoom: [
                    {
                        type: "inside",
                        show: true,
                        moveOnMouseMove: true,
                        startValue: 0,
                        endValue: 10,
                    },
                ],
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                series: [
                    {
                        barWidth: "8px",
                        name: "监测次数",
                        data: serieData?.monitor,
                        type: "bar",
                    },
                    {
                        barWidth: "8px",
                        name: "上传次数",
                        data: serieData?.upload,
                        type: "bar",
                    },
                ],
            }
            return cdata
        },
        handleV3BdHeader(state) {
            const marketWorkStatisticsVO = state.marketWorkStatisticsVO
            const cadta = [
                { id: 1, label: "监测总数", value: marketWorkStatisticsVO.count ?? 0 },
                { id: 2, label: "上传总数", value: marketWorkStatisticsVO.uploadCompleteCount ?? 0 },
                { id: 3, label: "审核总数", value: marketWorkStatisticsVO.platformCompleteAudit ?? 0 },
                { id: 4, label: "BD工作总数", value: marketWorkStatisticsVO.bdPeople ?? 0 },
            ]
            return cadta
        },
        handleV3UpLoadRate(state) {
            const xAxisData = state.upLoadRate.xData?.reverse()
            const serieData = state.upLoadRate.serieData
            const cdata = {
                color: [
                    "#FE01FD",
                    "#9E00FF",
                    "#4900FF",
                    "#0171FF",
                    "#00FDFF",
                    "#05FF00",
                    "#FFD700",
                ],
                grid: {},
                legend: {
                    icon: "roundRect",
                    top: 10,
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 8, // 设置 legend 中文字的字体大小为 14
                    },
                    data: [
                        "待上传率",
                        "待归还率",
                        "待审核率",
                        "已监测总次数",
                        "待上传",
                        "待归还",
                        "待审核",
                    ],
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "category",
                    // boundaryGap: false,
                    data: xAxisData,
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                dataZoom: [
                    {
                        type: "inside",
                        show: true,
                        moveOnMouseMove: true,
                    },
                ],
                yAxis: [
                    // 人数
                    {
                        type: "value",
                        name: "( 人数 )",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                    // 次数
                    {
                        type: "value",
                        name: "( 次数 )",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "待上传率",
                        type: "line",
                        symbol: "none",
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.waitUploadRate?.reverse(),
                    },
                    {
                        name: "待归还率",
                        type: "line",
                        symbol: "none",
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.waitReturnRate?.reverse(),
                    },
                    {
                        name: "待审核率",
                        type: "line",
                        symbol: "none",
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: serieData?.waitAuditedRate?.reverse(),
                    },
                    {
                        name: "已监测总次数",
                        type: "bar",
                        barWidth: 10,
                        yAxisIndex: 1,
                        data: serieData?.count?.reverse(),
                    },
                    {
                        name: "待上传",
                        type: "bar",
                        barWidth: 10,
                        yAxisIndex: 1,
                        data: serieData?.waitUploadCount?.reverse(),
                    },
                    {
                        name: "待归还",
                        type: "bar",
                        barWidth: 10,
                        yAxisIndex: 1,
                        data: serieData?.waitReturnCount?.reverse(),
                    },
                    {
                        name: "待审核",
                        type: "bar",
                        barWidth: 10,
                        yAxisIndex: 1,
                        data: serieData?.waitAuditedCount?.reverse(),
                    },
                ],
            }
            return cdata
        },
        handleV3RealTime(state) {
            const config = {
                header: [
                    "医院名称",
                    "姓名",
                    "年龄",
                    "性别",
                    "心电分析结果",
                    "监测日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "80", "80", "145", "145"],
                align: ["center", "center", "center", "center", "center", "center"],
                data: state.realTime,
            }
            return config
        },
        handleV3Kpi(state) {
            const config = {
                header: ["医院名称", "姓名", "设备号", "上传人", "上传日期"],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "145", "80", "145"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.kpi,
            }
            return config
        },
        handleDevice(state) {
            const data = state.device.map((item) => {
                return (item = [
                    handleString(item.hospitalName),
                    handleString(item.patientName),
                    item.age,
                    item.genderStr,
                    item.ecgResult,
                    item.inspectionTimeStr,
                ]);
            });
            const config = {
                header: [
                    "医院名称",
                    "姓名",
                    "年龄",
                    "性别",
                    "心电分析结果",
                    "监测日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["160", "80", "80", "80", "145", "145"],
                align: ["center", "center", "center", "center", "center", "center"],
                data,
            }
            return config
        },
        handleApply(state) {
            const data = state.apply.map((item) => {
                return (item = [
                    handleString(item.hospitalName),
                    handleString(item.patientName),
                    item.appTypeStr,
                    item.softwareVersion,
                    item.messagePrompt,
                    item.reportTimeStr,
                ]);
            });
            const config = {
                header: [
                    "医院名称",
                    "报告人",
                    "APP类型",
                    "软件版本",
                    "消息提示",
                    "报告日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["160", "80", "80", "80", "145", "145"],
                align: ["center", "center", "center", "center", "center", "center"],
                data,
            }
            return config
        },
        handleArchive(state) {
            const data = state.archive.map((item) => {
                return (item = [
                    handleString(item.hospitalName),
                    handleString(item.patientName),
                    item.genderStr,
                    item.birthdayStr,
                    item.age,
                    handleString(item.doctorName),
                    item.createTimeStr,
                ]);
            });
            const config = {
                header: [
                    "医院名称",
                    "姓名",
                    "性别",
                    "出生日期",
                    "年龄",
                    "对应医师",
                    "创建日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "80", "125", "80", "100", "145"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data,
            }
            return config
        },
        handleStock(state) {
            let cdata = {
                color: [
                    "#FFD700",
                    "#05FF00",
                    "#00FDFF",
                    "#0171FF",
                    "#4900FF",
                    "#9E00FF",
                ],
                grid: {
                    top: "40px",
                    left: "10px",
                    width: "94%",
                    height: "80%",
                    containLabel: true,
                },
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 8, // 设置 legend 中文字的字体大小为 14
                    },
                    data: [
                        "电极片",
                        "好络维心电仪",
                        "手机",
                        "物联网卡",
                        "10口充电桩",
                        "80口充电桩",
                    ],
                    top: "10px",
                    right: "10px",
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "value",
                    // data: ["胡金刚", "李云龙", "赵刚", "胡兵", "张强", "张建国", "德华"],
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: {
                    type: "category",
                    axisLabel: {
                        fontSize: 10,
                    },
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                series: [
                    {
                        barWidth: "4px",
                        name: "电极片",
                        data: [],
                        type: "bar",
                    },
                    {
                        barWidth: "4px",
                        name: "好络维心电仪",
                        data: [],
                        type: "bar",
                    },
                    {
                        barWidth: "4px",
                        name: "手机",
                        data: [],
                        type: "bar",
                    },
                    {
                        barWidth: "4px",
                        name: "物联网卡",
                        data: [],
                        type: "bar",
                    },
                    {
                        barWidth: "4px",
                        name: "10口充电桩",
                        data: [],
                        type: "bar",
                    },
                    {
                        barWidth: "4px",
                        name: "80口充电桩",
                        data: [],
                        type: "bar",
                    },
                ],
            }
            const name = {
                手机: "cellPhone",
                "80口充电桩": "eightyChargingPile",
                电极片: "electrodeSlice",
                好络维心电仪: "heartEquipment",
                物联网卡: "internetOfThingsCard",
                "10口充电桩": "thenChargingPile",
            };
            if (state.stock.xData) {
                cdata.yAxis.data = state.stock.xData.reverse().map(item => handleString(item))
                cdata.series = cdata.series.map((item) => {
                    item.data = state.stock.serieData[name[item.name]].reverse() ?? [];
                    return item;
                });
            }
            return cdata
        }
    },
    actions: {
        getV3HeartRateAggregate() {
            let monitorStartTime = "";
            let startTime = "";
            let type = "";
            let monitorEndTime = new Date().getTime();
            let endTime = new Date().getTime();
            let last30Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; // 最近30天
            let last365Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 365 * 3600 * 24 * 1000; // 最近365天/一年
            if (this.current === 1) {
                monitorStartTime = last30Days;
                startTime = last30Days;
                type = "MONTH";
            } else {
                monitorStartTime = last365Days;
                startTime = last30Days;
                type = "YEAR";
            }
            let request = {
                type,
                monitorStartTime,
                monitorEndTime,
                startTime: this.countStartTime,
                endTime: this.countEndTime,
                hospitalId: this.currentHospitalId,
                ...this.province,
                // countStartTime: this.countStartTime,
                // countEndTime: this.countEndTime
            }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            aggregate.getV3HeartRateAggregate(request).then(res => {
                const { data } = res
                console.log('心电聚合接口', data)
                this.head = data.head.sort((a, b) => a.index - b.index)
                this.monitor = data.monitor
                this.service = data.service.map(item => {
                    return (item = [
                        handleString(item.hospitalName),
                        item.people,
                        item.count,
                        item.uploadIng,
                        item.platformCompleteAudit,
                        item.toAudit,
                    ]);
                })
                this.upLoadRate = data.upLoadRate
                this.realTimeData = data.realTime
                this.marketWorkStatisticsVO = data.marketWorkStatisticsVO
                this.realTime = data.realTime.map(item => {
                    return (item = [
                        handleString(item.hospitalName),
                        handleString(item.patientName),
                        item.age,
                        item.genderStr,
                        item.ecgResult,
                        item.inspectionTimeStr,
                    ])
                })
                this.kpi = data.kpi.map(item => {
                    return (item = [
                        handleString(item.hospitalName),
                        handleString(item.patientName),
                        handleString(item.deviceSn, 4),
                        handleString(item.uploadName),
                        item.startUploadTimeStr,
                    ])
                })
                this.kpiData = data.kpi
                this.device = data.device
                this.apply = data.apply
                this.archive = data.archive
                this.stock = data.stock
            })
        },
        handleSelectItem(val) {
            if (val === "nearly_year") {
                this.current = 2;
            } else if (val === "nearly_day") {
                this.current = 1;
            }
            this.getV3HeartRateAggregate()
        },
        handleBdSelectTimeItem(item, index) {
            this.BdCurrent = index
            let endTime = new Date(new Date().toLocaleDateString()).getTime();
            let today = this.getTodayTime(24, endTime);
            let last7Days = endTime - 7 * 3600 * 24 * 1000; // 最近7天
            let last30Days = endTime - 30 * 3600 * 24 * 1000; // 最近30天
            switch (item.label) {
                case '全部':
                    this.countStartTime = '';
                    this.countEndTime = '';
                    break;
                case '今日':
                    this.countStartTime = today.startTime;
                    this.countEndTime = today.endTime;
                    break;
                case '近一周':
                    this.countStartTime = last7Days;
                    this.countEndTime = endTime;
                    break;
                default:
                    this.countStartTime = last30Days;
                    this.countEndTime = endTime;
            }
            this.getV3HeartRateAggregate()
        },
        getTodayTime(dayTime, time = new Date().getTime(), today) {
            // 判断 时间大小
            const startTime = new Date(
                new Date(new Date(time).toLocaleDateString()).getTime()
            ).getTime();
            let endTime = "";
            if (today === "today") {
                endTime = new Date(
                    new Date(new Date(time).toLocaleDateString()).getTime() +
                    dayTime * 60 * 60 * 1000 -
                    1
                ).getTime();
            } else {
                endTime = new Date(
                    new Date(new Date().toLocaleDateString()).getTime() +
                    dayTime * 60 * 60 * 1000 - 1
                ).getTime();
            }
            return { startTime, endTime };
        },
        handleAbnormalType(type) {
            if (type === 'device') {

            } else {

            }
        }
    }
})